import React from 'react'
import './index.css'

export default class Footer extends React.Component {
    render() {
        return (
            <footer className='footer'>
                
            </footer>
        )
    }
}